<template>
    <div class="Userfeedback">
        <div class="feed_div">
            <p class="div_title">培训结果反馈表</p>
            <p class="p_one">尊敬的老师：</p>
            <p class="p_two">非常感谢大家与我们一起完成了该本次课程。为了能提供更好的创新创业培训服务，优化课程，现邀请大家对我们的课程进行评价并提出建议。本调查表采用匿名设计，不是针对某具体课程的调查，而是针对一段时间内的培训的整体效果的调查，再次感谢您的积极配合！</p>
            <div class="giftDiv">
                <ul>
                    <li v-for="(item,index) in ruleForm.data" :key="index" :class="item.val==0?'uncheck':'Hasbeen'" @click="giftBox(item,index)">
                        <div class="gift_img">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="gift_text">
                            {{item.name}}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="p_div_o">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" size="small" class="demo-ruleForm">
                    <div class="form_div">
                        <div>
                            <el-form-item label="联系电话:" prop="mobile">
                                <el-input v-model="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="收货地址:" prop="address">
                                <el-input v-model="ruleForm.address" placeholder="请输入收货地址"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="110px" size="small" class="demo-ruleForm">
                    <div class="form_div">
                        <div>
                            <el-form-item label="课程名称:" prop="name">
                                <el-input v-model="Form.name" disabled></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="培训时间:" prop="time">
                                <el-input v-model="Form.time" disabled></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
            <div class="div_rate">
                <p class="div_p_title">一、在下列各个选项中，请您认为最恰当的选项进行打分。</p>
                <div class="butt_tile">
                    <!-- 培训内容 -->
                    <div class="butt_tile_div">
                        <div class="trainButt" v-preventReClick @click="aaa()">
                            <i class="iconfonts icons-peixun"></i>
                            <span>培训内容</span>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">1.1 </span>
                                <span class="_span_r">培训内容合适，符合自己的需求。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a11" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">1.2 </span>
                                <span class="_span_r">我认为培训中所学内容与我的工作密切相关，对工作有帮助。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a12" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">1.3 </span>
                                <span class="_span_r">培训提供的案例、思路给我启发。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a13" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">1.4 </span>
                                <span class="_span_r">配套培训资料的编排方式合理，易读。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a14" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">1.5 </span>
                                <span class="_span_r">培训的进度安排适中。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a15" :texts="texts" show-text/>
                            </div>
                        </div>
                    </div>
                    <!-- 导师水平 -->
                    <div class="butt_tile_div">
                        <div class="trainButt" v-preventReClick @click="aaa()">
                            <i class="iconfonts icons-jiaoshi"></i>
                            <span>导师水平</span>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">2.1 </span>
                                <span class="_span_r">导师的相关专业知识扎实，对培训内容的准备充分。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a21" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">2.2 </span>
                                <span class="_span_r">导师的思路清晰、语言表述清楚，易于掌握理解。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a22" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">2.3 </span>
                                <span class="_span_r">导师能够很好地回答学员的提问并引导学员讨论及处理问题。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a23" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">2.4 </span>
                                <span class="_span_r">导师授课技巧及课堂组织好，熟练运用引导式发问、角色扮演、体验、案例分析、 讨论等方法鼓励学员参与，从而提高培训效率。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a24" :texts="texts" show-text/>
                            </div>
                        </div>
                    </div>
                    <!-- 导师水平 -->
                    <div class="butt_tile_div">
                        <div class="trainButt" v-preventReClick @click="aaa()">
                            <i class="iconfonts icons-jichusheshihegongyongsheshishigu" style="font-size: 28px;vertical-align: middle;margin-right: 1px;
    margin-left: -9px;"></i>
                            <span>培训设施</span>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">3.1 </span>
                                <span class="_span_r">教室环境（温度、光线、整洁、安静程度）舒适，有助于学习。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a31" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">3.2 </span>
                                <span class="_span_r">教学设施（投影仪、白板、白板笔等）配备齐全，满足培训要求。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a32" :texts="texts" show-text/>
                            </div>
                        </div>
                    </div>
                    <!-- 导师水平 -->
                    <div class="butt_tile_div">
                        <div class="trainButt" v-preventReClick @click="aaa()">
                            <i class="iconfonts icons-fuwu" style="font-size: 28px;vertical-align: middle;margin-right: 1px;
    margin-left: -9px;"></i>
                            <span>后勤服务</span>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">4.1 </span>
                                <span class="_span_r">训练营前的通知工作认真细致，明确培训时间、地点、负责人等。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a41" :texts="texts" show-text/>
                            </div>
                        </div>
                        <div class="train_one clearfix">
                            <div class="tra_left fl">
                                <span class="_span_l">4.2 </span>
                                <span class="_span_r">训练营期间组织工作（签到、资料领取等）明确、周到、及时、迅速。</span>
                            </div>
                            <div class="tra_right fl">
                                <el-rate v-model="vForm.a42" :texts="texts" show-text/>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="div_p_title">二、提出建议以便改进。（可选）</p>
                <div class="butt_tile">
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="vForm.suggest"
                        maxlength="120"
                        show-word-limit
                    >
                    </el-input>
                </div>
            </div>
            <div class="sub_button" v-preventReClick @click="submit('ruleForm')">
                <span>提交</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            textarea:'',
            vForm:{
                a11:0,
                a12:0,
                a13:0,
                a14:0,
                a15:0,
                a21:0,
                a22:0,
                a23:0,
                a24:0,
                a31:0,
                a32:0,
                a41:0,
                a42:0,
                suggest:'',
            },
            texts:['非常差','不满意','一般','满意','非常好',],
            Form:{
                name:'',
                time:'',
            },
            ruleForm:{
                mobile:'',
                address:'',
                data:[],
            },
            rules:{
                mobile:[
                    { required: true, message: '请输入联系电话', trigger:'blur'},
                ],
                address:[
                    { required: true, message: '请输入收货地址', trigger:'blur'},
                ]
            },
        }
    },
    methods: {
        getapp(){
            this.axios.Stugetfb({
                token:this.$store.state.token,
                id:this.$route.query.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.Form = res.data.data.base
                    this.ruleForm = res.data.data.gift
                    this.vForm = res.data.data.value
                }
            }).catch(err=>{

            })
        },
        giftBox(item,val){
            this.ruleForm.data.forEach((el,index) =>{
                if(val==index){
                    el.val = 1
                }else{
                    el.val = 0
                }
            })
        },
        submit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var arr = []
                    var arr1 = []
                    this.ruleForm.data.filter(el=>{
                        if(el.val==1){
                            arr.push(el)
                        }
                    })
                    for(var key in this.vForm){
                        if(this.vForm[key]===0){
                            arr1.push(this.vForm[key])
                        }
                    }
                        console.log(arr1)
                    if(arr.length==0){
                        this.$message.error('请选择一份礼物')
                    }else if(arr1.length!=0){
                        this.$message.error('请为所有选项进行打分!')
                    }else{
                        this.axios.Stusetfb({
                            token:this.$store.state.token,
                            id:this.$route.query.cla_id,
                            value:this.vForm,
                            gift:this.ruleForm
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$message.success('提交成功')
                                this.$router.push({name:'Personalcenter'})
                            }else{
                                this.$message.error(res.data.message) 
                                this.getapp()
                            }
                        }).catch(err=>{

                        })
                    }
                    
                }else{
                    this.$message.error('联系电话和收货地址不能为空')
                }
            })
        }
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Userfeedback.css';
</style>
<style>
.form_div .el-form-item__label{
    color: #fff;
}
.form_div .el-input__inner,.form_div .el-input.is-disabled .el-input__inner{
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: center;
}
.tra_right .el-rate__text{
    color: #fff!important;
}
.tra_right .el-icon-star-off{
    color: #fff!important;
}
.butt_tile .el-textarea__inner{
    width: 100%;
    height: 84px;
    border-radius: 0;
    resize: none;
    background: transparent;
    color: #fff;
    padding: 5px 10px;
}
.butt_tile .el-textarea .el-input__count{
    background: transparent;
    color: #fff;
}
</style>